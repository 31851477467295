import React, { useState, useEffect } from 'react';

import Header from '../components/header';
import Footer from '../components/footer';
import axios from 'axios';

export default function Press() {

    const [lang, setLang] = useState(window.localStorage.getItem('locale'));
    const [header, setHeader] = useState([]);


    useEffect(() => {

        axios.post('https://staging.hellotree.dev/a/vertu-back/public/api/header')
            .then(response => {
                setHeader(response.data[0]);
            });
 
    }, []);


    useEffect(() => {

        axios.post('https://staging.hellotree.dev/a/vertu-back/public/api/header')
            .then(response => {
                setHeader(response.data[0]);
            });
        

    }, [lang]);

    function changeLang(locale) {
        window.localStorage.setItem('locale', locale);
        axios.defaults.params = {};
        axios.defaults.params['locale'] = locale;
        setLang(locale);
    }



    return (
        <div>
            <Header changeLang={changeLang} header={header} />
            <div className="card-columns">
                <div className="card">
                    <div className="f-53">
                        qwd qwd qwd qwd qw dqw dqw dqwd 
                        qwd qwd qwd qwd qw dqw dqw dqwd 
                        qwd qwd qwd qwd qw dqw dqw dqwd 
                        qwd qwd qwd qwd qw dqw dqw dqwd 
                        qwd qwd qwd qwd qw dqw dqw dqwd 
                        qwd qwd qwd qwd qw dqw dqw dqwd 
                        qwd qwd qwd qwd qw dqw dqw dqwd 
                    </div>
                </div>
                <div className="card">
                    <div className="f-53">
                        qwd qwd qwd qwd qw dqw dqw dqwd 
                        qwd qwd qwd qwd qw dqw dqw dqwd 
                        qwd qwd qwd qwd qw dqw dqw dqwd 
                    </div>
                </div>
                <div className="card">
                    <div className="f-53">
                        qwd qwd qwd qwd qw dqw dqw dqwd 
                        qwd qwd qwd qwd qw dqw dqw dqwd 
                        qwd qwd qwd qwd qw dqw dqw dqwd 
                        qwd qwd qwd qwd qw dqw dqw dqwd 
                        qwd qwd qwd qwd qw dqw dqw dqwd 
                        qwd qwd qwd qwd qw dqw dqw dqwd 
                        qwd qwd qwd qwd qw dqw dqw dqwd 
                        qwd qwd qwd qwd qw dqw dqw dqwd 
                        qwd qwd qwd qwd qw dqw dqw dqwd 
                        qwd qwd qwd qwd qw dqw dqw dqwd 
                        qwd qwd qwd qwd qw dqw dqw dqwd 
                        qwd qwd qwd qwd qw dqw dqw dqwd 
                        qwd qwd qwd qwd qw dqw dqw dqwd 
                        qwd qwd qwd qwd qw dqw dqw dqwd 
                    </div>
                </div>
            </div>
            <Footer header={header} />
        </div>
    );
}
