import React from "react";

export default function Footer(props) {
    return (
        <div className="footer position-relative py-3">
            <div className="h-100">
                <div className="middle color-lightgrey f-12 text-center h-100">
                    {props.header.rights}
                </div>
            </div>
        </div>
    );
}
