import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import logo from "../assets/images/logo.png";
import ReactPlayer from 'react-player/youtube';
import Header from '../components/header';
import Modal from '../components/modal';
import Footer from '../components/footer';
import axios from 'axios';
import { ProgressBar } from 'react-bootstrap';
import screenfull from 'screenfull'

const positions = [
    'col-auto offset-lg-4 offset-2',
    'col-auto offset-lg-1 offset-7 pt-lg-0 pt-5 pb-lg-0 pb-4',
    'col-auto offset-lg-3 offset-1 pt-lg-0 pt-5',
    'col-auto offset-lg-6 offset-4',
    'col-auto offset-lg-9 offset-1 offset-md-6 pt-lg-0 pt-5',
    'col-auto offset-lg-8 offset-1',
];

export default function Home() {

    const [svgLoad, setSvgLoad] = useState(false);
    const [logoLoad, setLogoLoad] = useState(false);
    const [videoOepnId, setVideoOpenId] = useState(null);
    const [playingId, setPlayingId] = useState(null);
    const [seconds, setSeconds] = useState({});
    const [durations, setDurations] = useState({});
    const [progress, setProgress] = useState({});
    const [episodes, setEpisodes] = useState([]);
    const [header, setHeader] = useState([]);

    const [lang, setLang] = useState(window.localStorage.getItem('locale'));
    const [reactPlayerOpened, setReactPlayerOpened] = useState([]);
    const player = useRef(null);

    useEffect(() => {
        axios.post('https://backend.vertulaserie.xyz/api/episodes')
            .then(response => {
                setEpisodes(response.data)
            });
        axios.post('https://backend.vertulaserie.xyz/api/header')
            .then(response => {
                setHeader(response.data[0]);
            });
        setSvgLoad(true);
        setLogoLoad(true);
    }, []);

    useEffect(() => {
        axios.post('https://backend.vertulaserie.xyz/api/episodes')
            .then(response => {
                setEpisodes(response.data)
            });
        axios.post('https://backend.vertulaserie.xyz/api/header')
            .then(response => {
                setHeader(response.data[0]);
            });

    }, [lang]);

    useLayoutEffect(() => {

    }, []);

    useEffect(() => {
        let newProgress = {};
        for (const id in seconds) newProgress[id] = 100 * seconds[id] / durations[id];
        setProgress(newProgress);
    }, [seconds, durations]);

    function changeLang(locale) {
        window.localStorage.setItem('locale', locale);
        axios.defaults.params = {};
        axios.defaults.params['locale'] = locale;
        setLang(locale);
    }

    return (
        <div className="bg-image h-100 d-flex flex-column">
            <div className={`close-btn ${videoOepnId && 'show'}`} onClick={() => {
                setVideoOpenId(null);
                setPlayingId(null);
            }} >
                <img className="pointer" src="./images/close-btn.svg" alt="" />
            </div>
            <div className={`overlay ${videoOepnId && 'show'}`} onClick={() => {
                setVideoOpenId(null);
                setPlayingId(null);
            }}></div>
            <video autoPlay muted loop id="bg-video">
                <source src="./images/video/lights.mp4" type="video/mp4" />
                    Your browser does not support HTML5 video.
            </video>
            <Header changeLang={changeLang} header={header} />
            <div className="nav-margin home bg-black  container-fluid mx-0 d-flex flex-grow-1">
                <div className="row position-relative flex-grow-1">
                    <img src={logo} alt="" className={`logo px-5 d-none d-lg-block ${logoLoad && 'show'}`} />

                    <svg className={` bg-words w-100 d-none d-lg-block svg ${svgLoad && 'show'}`} xmlns="http://www.w3.org/2000/svg" width="1638" height="863" viewBox="0 0 1638 863">
                        <g id="Group_42" data-name="Group 42" transform="translate(-143 -128)">
                            <text id="Victime" transform="translate(133 172)" fill="#3d3e3f" fontSize="14" className="gotham-book"><tspan x="0" y="0">{lang === 'fr' ? 'VICTIME' : 'VICTIM'}</tspan></text>
                            <text id="vengeance" transform="translate(454 170)" fill="#3d3e3f" fontSize="14" className="gotham-book"><tspan x="0" y="0">{lang === 'fr' ? 'VENGEANCE' : 'VENGENCE'}</tspan></text>
                            <text id="vertile" transform="translate(950 193)" fill="#3d3e3f" fontSize="14" className="gotham-book"><tspan x="0" y="0">{lang === 'fr' ? 'VERTILE' : 'VALUES'}</tspan></text>
                            <text id="verdict" transform="translate(1123 300)" fill="#3d3e3f" fontSize="14" className="gotham-book"><tspan x="0" y="0">{lang === 'fr' ? 'VERDICT' : 'VERDICT'}</tspan></text>
                            <text id="voile" transform="translate(1351 231)" fill="#3d3e3f" fontSize="14" className="gotham-book"><tspan x="0" y="0">{lang === 'fr' ? 'VOILE' : 'VEIL'}</tspan></text>
                            <text id="vierge" transform="translate(1579 358)" fill="#3d3e3f" fontSize="14" className="gotham-book"><tspan x="0" y="0">{lang === 'fr' ? 'VIERGE' : 'VIRGIN'}</tspan></text>
                            <text id="victime-2" data-name="victime" transform="translate(1579 817)" fill="#3d3e3f" fontSize="14" className="gotham-book"><tspan x="0" y="0">{lang === 'fr' ? 'VICTIME' : 'VICTIM'}</tspan></text>
                            <text id="vecu" transform="translate(1727 213)" fill="#3d3e3f" fontSize="14" className="gotham-book"><tspan x="0" y="0">{lang === 'fr' ? 'VECU' : 'VANDAL'}</tspan></text>
                            <text id="venin" transform="translate(925 910)" fill="#3d3e3f" fontSize="14" className="gotham-book"><tspan x="0" y="0">{lang === 'fr' ? 'VENIN' : 'VENIN'}</tspan></text>
                            <text id="vain" transform="translate(724 817)" fill="#3d3e3f" fontSize="14" className="gotham-book"><tspan x="0" y="0">{lang === 'fr' ? 'VAIN' : 'VAIN'}</tspan></text>
                            <text id="vil" transform="translate(212 818)" fill="#3d3e3f" fontSize="14" className="gotham-book"><tspan x="0" y="0">{lang === 'fr' ? 'VIL' : 'VILE'}</tspan></text>
                            <text id="vecu-2" data-name="vecu" transform="translate(144 985)" fill="#3d3e3f" fontSize="14" className="gotham-book"><tspan x="0" y="0">{lang === 'fr' ? 'VECU' : 'VANDAL'}</tspan></text>
                            <text id="vaincu" transform="translate(259 309)" fill="#3d3e3f" fontSize="14" className="gotham-book"><tspan x="0" y="0">{lang === 'fr' ? 'VAINCU' : 'VICE'}</tspan></text>
                            <text id="viril" transform="translate(156 501)" fill="#3d3e3f" fontSize="14" className="gotham-book"><tspan x="0" y="0">{lang === 'fr' ? 'VIRIL' : 'VIRILE'}</tspan></text>
                        </g>
                    </svg>

                    <svg className={`bg-words w-100 h-100 d-lg-none  svg ${svgLoad && 'show'}`} xmlns="http://www.w3.org/2000/svg" width="1638" height="863" viewBox="0 0 438 863">
                        <g id="Group_42" data-name="Group 42" transform="translate(-143 -128)">
                            <text id="Victime" transform="translate(143 230)" fill="#3d3e3f" fontSize="15" className="gotham-book"><tspan x="0" y="0">{lang === 'fr' ? 'VICTIME' : 'VICTIM'}</tspan></text>
                            <text id="vengeance" transform="translate(454 170)" fill="#3d3e3f" fontSize="15" className="gotham-book"><tspan x="0" y="0">{lang === 'fr' ? 'VENGEANCE' : 'VENGENCE'}</tspan></text>
                            <text id="vertile" transform="translate(950 163)" fill="#3d3e3f" fontSize="15" className="gotham-book"><tspan x="0" y="0">{lang === 'fr' ? 'VERTILE' : 'VALUES'}</tspan></text>
                            <text id="verdict" transform="translate(1123 300)" fill="#3d3e3f" fontSize="15" className="gotham-book"><tspan x="0" y="0">{lang === 'fr' ? 'VERDICT' : 'VERDICT'}</tspan></text>
                            <text id="voile" transform="translate(1351 231)" fill="#3d3e3f" fontSize="15" className="gotham-book"><tspan x="0" y="0">{lang === 'fr' ? 'VOILE' : 'VEIL'}</tspan></text>
                            <text id="vierge" transform="translate(1579 358)" fill="#3d3e3f" fontSize="15" className="gotham-book"><tspan x="0" y="0">{lang === 'fr' ? 'VIERGE' : 'VIRGIN'}</tspan></text>
                            <text id="victime-2" data-name="victime" transform="translate(1579 817)" fill="#3d3e3f" fontSize="15" className="gotham-book"><tspan x="0" y="0">{lang === 'fr' ? 'VICTIME' : 'VICTIM'}</tspan></text>
                            <text id="vecu" transform="translate(1727 213)" fill="#3d3e3f" fontSize="15" className="gotham-book"><tspan x="0" y="0">{lang === 'fr' ? 'VECU' : 'VANDAL'}</tspan></text>
                            <text id="venin" transform="translate(925 910)" fill="#3d3e3f" fontSize="15" className="gotham-book"><tspan x="0" y="0">{lang === 'fr' ? 'VENIN' : 'VENIN'}</tspan></text>
                            <text id="vain" transform="translate(724 817)" fill="#3d3e3f" fontSize="15" className="gotham-book"><tspan x="0" y="0">{lang === 'fr' ? 'VAIN' : 'VAIN'}</tspan></text>
                            <text id="vil" transform="translate(212 818)" fill="#3d3e3f" fontSize="15" className="gotham-book"><tspan x="0" y="0">{lang === 'fr' ? 'VIL' : 'VILE'}</tspan></text>
                            <text id="vecu-2" data-name="vecu" transform="translate(144 985)" fill="#3d3e3f" fontSize="15" className="gotham-book"><tspan x="0" y="0">{lang === 'fr' ? 'VECU' : 'VANDAL'}</tspan></text>
                            <text id="vaincu" transform="translate(259 309)" fill="#3d3e3f" fontSize="15" className="gotham-book"><tspan x="0" y="0">{lang === 'fr' ? 'VAINCU' : 'VICE'}</tspan></text>
                            <text id="viril" transform="translate(456 641)" fill="#3d3e3f" fontSize="15" className="gotham-book"><tspan x="0" y="0">{lang === 'fr' ? 'VIRIL' : 'VIRILE'}</tspan></text>
                        </g>
                    </svg>

                    <div className="col-12">
                        {episodes.map((epi, i) => (

                            <React.Fragment key={epi.id}>

                                <div className="row episode-class">
                                    <div className={`${positions[i]} ${videoOepnId !== epi.id && 'z-99'} pt-id pt-id-${i} `}>
                                        <div className={`f-16 color-grey pointer episode-hover gotham-bold text-white o-100 capitalize ${videoOepnId && 'hide-titles'}`}

                                            onMouseEnter={() => {
                                                if (window.innerWidth >= 992) {
                                                    setVideoOpenId(epi.id);
                                                    let temp = []

                                                    for (let j = 0; j < episodes.length; j++) {
                                                        if (episodes[j].id === epi.id) {
                                                            if (epi.display === 'd-block') {
                                                                temp.push({ name: episodes[j].title, display: 'd-none', id: j, play: 'd-block' })
                                                                setVideoOpenId(null);
                                                            }
                                                            else {
                                                                temp.push({ name: episodes[j].title, display: 'd-block', id: j, play: 'd-block' })
                                                            }
                                                        }
                                                        else {
                                                            temp.push({ name: episodes[j].title, display: 'd-none', id: j, play: episodes[j].play })
                                                        }
                                                    }
                                                }
                                            }}

                                            onClick={() => {
                                                if (window.innerWidth <= 991) {
                                                    setVideoOpenId(epi.id);
                                                    let temp = []

                                                    for (let j = 0; j < episodes.length; j++) {

                                                        if (episodes[j].id === epi.id) {
                                                            if (epi.display === 'd-block') {
                                                                temp.push({ name: episodes[j].title, display: 'd-none', id: j, play: 'd-block' })
                                                                setVideoOpenId(null);
                                                            }
                                                            else {
                                                                temp.push({ name: episodes[j].title, display: 'd-block', id: j, play: 'd-block' })
                                                            }
                                                        }
                                                        else {
                                                            temp.push({ name: episodes[j].title, display: 'd-none', id: j, play: episodes[j].play })
                                                        }
                                                    }
                                                }
                                            }}>Episode {i + 1}/{episodes.length}</div>
                                        <div className={`f-20 text-white gotham-bold capital pointer episode ${videoOepnId && 'hide-titles'}`} >
                                            {/* {epi.title} */}
                                        </div>
                                        <div className={`react-player-position pos-${i} ${epi.id === videoOepnId && 'show'}`} onMouseLeave={() => {
                                            setVideoOpenId(null);
                                            setPlayingId(null);
                                        }}>
                                            {
                                                reactPlayerOpened.indexOf(epi.id) === -1 ?
                                                    <Modal

                                                        epi={epi}
                                                        reactPlayerOpened={reactPlayerOpened}
                                                        setReactPlayerOpened={setReactPlayerOpened}
                                                        setPlayingId={setPlayingId}

                                                    />
                                                    :
                                                    <ReactPlayer

                                                        ref={player}
                                                        className="w-lg-auto w-100 video-item"
                                                        onDuration={e => {
                                                            let newDurations = { ...durations };
                                                            newDurations[epi.id] = e;
                                                            setDurations(newDurations);
                                                        }}
                                                        onProgress={e => {
                                                            let newSeconds = { ...seconds };
                                                            newSeconds[epi.id] = e.playedSeconds;
                                                            setSeconds(newSeconds);
                                                        }}
                                                        onStart={() => screenfull.request(player.current.wrapper)}
                                                        onPlay={() => setPlayingId(epi.id)}
                                                        playing={playingId === epi.id}
                                                        controls={true}
                                                        url={epi.youtube_link}
                                                        config={{
                                                            youtube: {
                                                                playerVars: { modestbranding: 1 }
                                                            }
                                                        }}
                                                    />
                                            }
                                        </div>
                                        <div className={`progress-wrapper ${(progress[epi.id] && progress[epi.id] !== 0) ? 'show' : ''} `} >
                                            <ProgressBar now={progress[epi.id]} />
                                        </div>
                                    </div>
                                </div>
                                {i === 2 && (
                                    <div className="row justify-content-center d-lg-none">
                                        <div className="col text-center">
                                            <img src={logo} alt="" className={`logo py-5 ${logoLoad && 'show'}`} />
                                        </div>
                                    </div>
                                )}

                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </div>
            <Footer header={header} />
        </div>
    );
}
