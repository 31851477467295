import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

export default function Header(props) {

    const [menuOpen, setMenuOpen] = useState(false);
    const [language, setLanguage] = useState(window.localStorage.getItem('locale'));

    useEffect(() => {
        setLanguage(window.localStorage.getItem('locale'));
    }, []);

    return (
        <div className="">
            <nav className="nav fixed-top">
                <div className="burger-wrapper" onClick={() => setMenuOpen(!menuOpen)}>
                    <div className={`burger ${menuOpen && 'open'}`}></div>
                </div>
                <div className={`side-menu h-100 ${menuOpen && 'show'}`}>
                    <div className="container-fluid h-100 d-none d-lg-flex">
                        <div className="row h-100">
                            <div className="col-auto px-4 align-self-center">
                                <div className="pl-5 text-center f-14 gotham-medium">
                                    <Link className="o-100 display-link text-white text-center f-14 gotham-medium capital" to={`/about`}>{props.header.about}</Link>
                                </div>
                            </div>
                            {/* <div className="col-auto px-4 align-self-center border-left-red">
                                <div className="text-center f-14 gotham-medium">
                                    <Link className="o-100 display-link text-white text-center f-14 gotham-medium capital" to={`/press`}>{props.header.press}</Link>
                                </div>
                            </div> */}
                        </div>
                    </div>

                    <div className="mobile-side-menu d-lg-none position-relative">
                        <div className="pt-80"></div>
                        <div className="pl-4 menu-item border-top-dark middle justify-content-start">
                            <div className="text-center f-14 gotham-medium">
                                <Link className="o-100 display-link text-white text-center f-14 gotham-medium capital" to={`./about`}>{props.header.about}</Link>
                            </div>
                        </div>
                        {/* <div className="pl-4 menu-item border-top-dark middle justify-content-start">
                            <div className="text-center f-14 gotham-medium">
                                <Link className="o-100 display-link text-white text-center f-14 gotham-medium capital" to={`./press`}>{props.header.press}</Link>
                            </div>
                        </div> */}
                        <div className="pl-4 menu-item border-top-dark middle justify-content-start">
                            <span className={`display-link pointer text-center f-14 gotham-medium color-grey ${language === "fr" && 'active'}`} onClick={() => {
                                props.changeLang('fr');
                                setLanguage('fr');
                            }}>FR</span>
                            <span className="px-2 color-grey f-14">/</span>
                            <span className={`display-link pointer text-white text-center f-14 gotham-medium ${language === "en" && 'active'}`} onClick={() => {
                                props.changeLang('en');
                                setLanguage('en');
                            }}>ENG</span>
                        </div>

                        <div className={`social-media-mobile`}>
                            <div className="row justify-content-center">
                                <div className="col-12">
                                    <div className="capital gotham-medium f-10 text-white o-45 text-center pb-2">
                                        Follow us on
                                    </div>
                                </div>
                                {
                                    props.header.facebook_link &&
                                    <>
                                        <div className="col-auto">
                                            <a href={props.header.facebook_link} className="middle">
                                                <img src="./images/facebook.svg" alt="social-media" className="pointer fb" />
                                            </a>
                                        </div>
                                        <div className="border-left-red"></div>
                                    </>}

                                {
                                    props.header.twitter_link &&
                                    <>
                                        <div className="col-auto">
                                            <a href={props.header.twitter_link} className="middle">
                                                <img src="./images/twitter.svg" alt="social-media" className="pointer" />
                                            </a>
                                        </div>
                                        <div className="border-left-red"></div>
                                    </>}

                                {
                                    props.header.insta_link &&
                                    <>
                                        <div className="col-auto">
                                            <a href={props.header.insta_link} className="middle">
                                                <img src="./images/insta.svg" alt="social-media" className="pointer" />
                                            </a>
                                        </div>
                                        <div className="border-left-red"></div>
                                    </>}
                                {
                                    props.header.youtube_link &&
                                    <>
                                        <div className="col-auto">
                                            <a href={props.header.youtube_link} className="middle">
                                                <img src="./images/youtube.svg" alt="social-media" className="pointer" />
                                            </a>
                                        </div>
                                    </>}
                            </div>
                        </div>
                        <div className="gotham-light color-lightgrey text-center w-100 pt-3 border-top-dark header-copyrights f-10">
                            {props.header.rights}
                        </div>
                    </div>
                </div>

                <div className="languages d-lg-flex d-none">
                    <span className={`display-link pointer text-center f-14 gotham-medium color-grey ${language === "fr" && 'active'}`} onClick={() => {
                        props.changeLang('fr')
                        setLanguage('fr');
                    }}>FR</span>
                    <span className="px-2 color-grey f-14">/</span>
                    <span className={`display-link pointer text-white text-center f-14 gotham-medium ${language === "en" && 'active'}`} onClick={() => {
                        props.changeLang('en')
                        setLanguage('en');
                    }}>ENG</span>
                </div>

                <div className={`gotham-medium capital f-14 text-white header-title d-none d-lg-flex ${menuOpen && 'hide'}`}>
                    <div className="social-media">
                        <div className="row justify-content-center">
                            {
                                props.header.facebook_link &&
                                <>
                                    <div className="col-auto">
                                        <a href={props.header.facebook_link} className="middle">
                                            <img src="./images/facebook.svg" alt="social-media" className="pointer fb" />
                                        </a>
                                    </div>
                                    <div className="border-left-red"></div>
                                </>}

                            {
                                props.header.twitter_link &&
                                <>
                                    <div className="col-auto">
                                        <a href={props.header.twitter_link} className="middle">
                                            <img src="./images/twitter.svg" alt="social-media" className="pointer" />
                                        </a>
                                    </div>
                                    <div className="border-left-red"></div>
                                </>}

                            {
                                props.header.insta_link &&
                                <>
                                    <div className="col-auto">
                                        <a href={props.header.insta_link} className="middle">
                                            <img src="./images/insta.svg" alt="social-media" className="pointer" />
                                        </a>
                                    </div>
                                    <div className="border-left-red"></div>
                                </>}
                            {
                                props.header.youtube_link &&
                                <>
                                    <div className="col-auto">
                                        <a href={props.header.youtube_link} className="middle">
                                            <img src="./images/youtube.svg" alt="social-media" className="pointer" />
                                        </a>
                                    </div>
                                </>}
                            <div className="col-12">
                                <div className="gotham-medium f-12 lower text-white o-100 text-center pt-2">
                                    <Link className="display-link f-12 text-white o-100" to="./">{props.header.vertu}</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className={`gotham-medium capital f-14 text-white header-title d-lg-none o-100 ${menuOpen && ''}`}>
                    <Link className="display-link gotham-medium capital f-14 text-white o-100" to="./">{props.header.vertu}</Link>
                </div>
            </nav>
        </div >
    );
}
