import React from "react";

export default function Modal({ reactPlayerOpened, setReactPlayerOpened, setPlayingId, epi }) {

    return (
        <div className="popup-modal" onClick={() => {
            let newReactPLayerOpened = [...reactPlayerOpened];
            newReactPLayerOpened.push(epi.id);
            setReactPlayerOpened(newReactPLayerOpened);
            setPlayingId(epi.id);
            console.log('test')
        }}>
            <img src={epi.image} alt="" />
            <img className="play-btn" src="./images/play-btn.svg" alt="play-btn" />
            <div className="popup-modal-text">
                <div className="f-14 text-white o-45">
                    {epi.subtitle}
                </div>
                <div className="f-24 text-white gotham-bold capital">
                    {epi.title}
                </div>
            </div>
        </div>
    );
}
