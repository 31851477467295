import React, { useState, useEffect, useRef } from 'react';

import Header from '../components/header';
import Footer from '../components/footer';
import Cast from '../components/cast';
import { Link } from "react-router-dom";

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';
import axios from 'axios';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export default function About() {
    const prevRef = useRef(null);
    const nextRef = useRef(null);

    const [lang, setLang] = useState(window.localStorage.getItem('locale'));
    const [loaded, setLoaded] = useState(false);
    const [casts, setCasts] = useState([]);
    const [crew, setCrew] = useState([]);
    const [header, setHeader] = useState([]);
    const [posters, setPosters] = useState([]);
    const [about, setAbout] = useState([]);
    const [downloadable, setDownloadable] = useState(0);

    useEffect(() => {

        axios.post('https://backend.vertulaserie.xyz/api/casts')
            .then(response => {
                setCasts(response.data);
            });
        axios.post('https://backend.vertulaserie.xyz/api/crew')
            .then(response => {
                setCrew(response.data);
            });
        axios.post('https://backend.vertulaserie.xyz/api/posters')
            .then(response => {
                setPosters(response.data);
            });
        axios.post('https://backend.vertulaserie.xyz/api/header')
            .then(response => {
                setHeader(response.data[0]);
            });
        axios.post('https://backend.vertulaserie.xyz/api/about-page')
            .then(response => {
                setAbout(response.data[0]);
            });
    }, []);

    useEffect(() => {
        axios.post('https://backend.vertulaserie.xyz/api/casts')
            .then(response => {
                setCasts(response.data);
            });
        axios.post('https://backend.vertulaserie.xyz/api/crew')
            .then(response => {
                setCrew(response.data);
            });
        axios.post('https://backend.vertulaserie.xyz/api/posters')
            .then(response => {
                setPosters(response.data);
            });
        axios.post('https://backend.vertulaserie.xyz/api/header')
            .then(response => {
                setHeader(response.data[0]);
            });
        axios.post('https://backend.vertulaserie.xyz/api/about-page')
            .then(response => {
                setAbout(response.data[0]);
            });

    }, [lang]);

    function changeLang(locale) {
        window.localStorage.setItem('locale', locale);
        axios.defaults.params = {};
        axios.defaults.params['locale'] = locale;
        setLang(locale);
    }

    return (
        <div className="about-page">
            <Header changeLang={changeLang} header={header} />
            <div className="about nav-margin bg-dark">
                <div className="container px-lg-0 px-4">
                    <div className="row">
                        <div className="col-12">
                            <div className="gotham-book d-flex f-14 text-white">

                                <Link className="gotham-book f-14 text-white capital o-70" to="./">
                                    <img className="pr-2 pb-1" src="./images/back-btn.svg" alt="back home" />
                                    {header.home}
                                </Link>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="f-40 text-white gotham-bold capital pb-120 pt-lg-4 pt-3">
                                {about.title}
                            </div>
                        </div>
                    </div>
                    <div className="pl-140 row">
                        <div className="col-12">
                            <div className="color-red f-18 gotham-bold capital">
                                {about.synopsis}
                            </div>
                            <div className="text-white f-18 gotham-book">
                                {about.description}
                            </div>
                        </div>
                        <div className="col-12 pt-70">
                            <div className="color-red f-18 gotham-bold capital">
                                {about.crew}
                            </div>
                        </div>

                        <div className="col-lg-6 col-12">
                            {crew.slice(0, ((crew.length / 2 <= 0.9) ? (crew.length) : (crew.length / 2))).map((member, i) => (
                                <div className="pr-lg-5 lh-2" key={i}>
                                    <span className="text-white f-18 gotham-bold pr-2">
                                        {member.title}
                                    </span>
                                    <span className="text-white f-18 gotham-book">
                                        {member.names}
                                    </span>
                                </div>
                            ))}
                        </div>
                        <div className="col-lg-6 col-12">

                            {crew.slice(crew.length / 2, crew.length).map((member, i) => (
                                <div className="pl-lg-5 lh-2" key={i}>
                                    <span className="text-white f-18 gotham-bold pr-2">
                                        {member.title}
                                    </span>
                                    <span className="text-white f-18 gotham-book">
                                        {member.names}
                                    </span>
                                </div>
                            ))}
                        </div>

                        <div className="col-12 pt-70 pb-2">
                            <div className="color-red f-18 gotham-bold capital">
                                {about.cast}
                            </div>
                        </div>


                        {casts.map((cast, i) => (
                            <React.Fragment key={i}>
                                {i === 0 ?
                                    <div className="">
                                        <div className="pb-lg-5 pb-4 col-12">
                                            <Cast props={cast} />
                                        </div>
                                    </div>
                                    :
                                    (i === 1 ?
                                        <>
                                            <div className="border-top-grey pb-lg-5 pb-4 col-12"></div>
                                            <div className="pb-lg-5 pb-4 col-12">
                                                <Cast props={cast} />
                                            </div>
                                        </>

                                        :

                                        (
                                            i === 2 ?
                                                <>
                                                    <div className="border-top-grey pb-lg-5 pb-4 col-12"></div>
                                                    <div className="pb-lg-5 pb-4 col-12">
                                                        <Cast props={cast} />
                                                    </div>

                                                    <div className={`col-12 pb-3 ${loaded && 'd-none'}`}>
                                                        <div className="text-center gotham-bold pointer capitalize text-white o-100 " onClick={() => {
                                                            setLoaded(true);
                                                            // setPlayingId(null);
                                                        }}>
                                                            <u>view all</u>
                                                        </div>
                                                    </div>
                                                </>

                                                :

                                                (
                                                    <>
                                                        <div className={`border-top-grey pb-lg-5 pb-4 col-12 ${loaded ? 'show-cast' : 'hide-cast'}`}></div>
                                                        <div className={`pb-lg-5 pb-4 col-12 ${loaded ? 'show-cast' : 'hide-cast'}`}>
                                                            <Cast props={cast} />
                                                        </div>
                                                    </>
                                                )
                                        )
                                    )
                                }
                            </React.Fragment>
                        ))}

                        <div className="col-12 pt-70 pb-120">
                            <div className="left-arrow" ref={prevRef}>
                                <img className="pointer" src="./images/left-arrow.svg" alt="left arrow" />
                            </div>
                            <div className="right-arrow" ref={nextRef}>
                                <img className="pointer" src="./images/right-arrow.svg" alt="right arrow" />
                            </div>
                            <Swiper

                                breakpoints={{
                                    991: {
                                        slidesPerView: 3,
                                    },
                                    768: {
                                        slidesPerView: 2,
                                    },
                                    320: {
                                        slidesPerView: 1,
                                    },
                                }}
                                navigation={{
                                    prevEl: prevRef.current,
                                    nextEl: nextRef.current,
                                }}
                                onSwiper={(swiper) => {
                                    // Delay execution for the refs to be defined
                                    setTimeout(() => {
                                        swiper.params.navigation.prevEl = prevRef.current
                                        swiper.params.navigation.nextEl = nextRef.current
                                        swiper.navigation.destroy()
                                        swiper.navigation.init()
                                        swiper.navigation.update()
                                    })
                                }}
                                pagination={{ clickable: true }}
                                spaceBetween={15}
                                slidesPerView={3}
                                onSlideChange={(e) => {
                                    setDownloadable(e.activeIndex);
                                }}

                            >

                                {posters.map((poster, i) => (
                                    <SwiperSlide key={i}>
                                        <div className="image-wrapper-130">
                                            <img className="image-absolute" src={poster.image} alt={poster.slug} />
                                        </div>
                                        <div className="text-center pt-lg-3 pt-4 pb-4 d-none d-lg-block">
                                            <a className="text-center f-14 text-white capital gotham-bold" href={poster.downloadable_file} target="_blank" download rel="noreferrer"><u>{about.download_poster}</u></a>
                                        </div>
                                    </SwiperSlide>

                                ))}
                            </Swiper>
                            <div className="text-center pt-lg-3 pt-4 pb-4   d-lg-none">
                                <a className="text-center f-14 text-white capital gotham-bold" href={posters[downloadable] ? posters[downloadable].downloadable_file : null} target="_blank" download rel="noreferrer"><u>{about.download_poster}</u></a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer header={header} />
        </div>
    );
}
