import './App.css';
import React, { useEffect } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import About from "./pages/about";
import Home from "./pages/home";
import Press from "./pages/press";
import axios from 'axios';

function App() {
  useEffect(() => {

    if(window.localStorage.getItem('locale') === 'en' || window.localStorage.getItem('locale') === 'fr'){
      axios.defaults.params = {};
      axios.defaults.params['locale'] = window.localStorage.getItem('locale');
    }
    else{
      window.localStorage.setItem('locale', 'fr');
      axios.defaults.params = {};
      axios.defaults.params['locale'] = 'fr';
    }
    
  }, []);


  return (
    <Router>
        <Switch>
            <Route path="/about">
                <About />
            </Route>
            <Route path="/press">
                <Press />
            </Route>
            <Route path="/">
                <Home />
            </Route>
        </Switch>
    </Router>

  );
}

export default App;
