import React from "react";

export default function Modal({props}) {

    return (
        <div className="cast row">
            <div className="col-auto pr-lg-3 pr-2">
                <img src={props.image} alt="" />
            </div>
            <div className="col pl-lg-3 pl-1 align-self-center">
                <div className="gotham-bold text-white o-100 f-32 capital">
                    {props.name}
                </div>
                <div className="text-white o-100 gotham-light f-22 capital">
                    {props.serie_name}
                </div>
            </div>
        </div>
    );
}
